@import "../../bower_components/singularity/stylesheets/singularitygs";
@import "../../bower_components/breakpoint-sass/stylesheets/breakpoint";
@import "common/variables";
@import "common/_mixins.scss";
@import 'common/fonts';
@import "common/clearfix";

@include sgs-change('debug', true);
@include add-grid(12);
@include add-gutter(1/4);
@include add-gutter-style('split');



html, body {
  height: 100%;
  font-size: 16px;
  //background-color: $bgcolor; // check with left background bug
}

body {
  margin: 0;
  font-family: $basefont;
  font-size: 16px;
  color: $textColor;

  &.homepage {
    background: url(../img/water-bgr.png) no-repeat center;
    background-size: cover;
    overflow: hidden;
  }
}
h1,h2,h3,h4,h5 {
  margin: 0;
}
ol, ul, blockquote {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  font-family: $secondfont;
  color: $baselink;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
p {
  line-height: 1.3rem;
}
.btn {
  background: $firstColor;
  &:hover {
    background-color: $colorActive;
  }
}
.btn__icon.fa.fa-link {
  margin-right: 0.4rem;
}
.flx {
  @include flexbox();
}
.fl-right{
  float: right;
}
.fl-left {
  float: left;
}
.tx-right {
  text-align: right;
}
.icon {
  width: 35px;
  height: 25px;
  fill: rgba($basefontColor, 0.96);
  fill-opacity: .5;
  transition: fill-opacity .3s;
}
a .icon:hover{
  fill: rgba($colorActive, 1);
}
.container {
  //@include background-grid($color: blue);
  position: relative;
}
.fullscreen {
  //
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.main__page {
  margin: 0 auto;
  background: #fbf8f1;
}
.wrapper {
  width: 100%;
  height: 100%;
  @include flexbox(column,nowrap,space-between);
}

.main_fullscreen {
  width: 100%;
  @include  flexbox($jcontent: center, $align: center)
}
.span3{
  @include grid-span(3);
}
.span8 {
  @include  grid-span(8,5);
}

// for the aboutme section
.span6 {
  @include grid-span(6)
}
.span6_7{
  @include grid-span(6,7)
}

//for the blog section
@include add-gutter(0);
.span4 {
  @include grid-span(4);
}
.span4_5 {
  @include grid-span(4,5);
}
.span4_8 {
  @include grid-span(4,9);
}

.page__title {
  position: relative;
  font: 3.93rem/5rem $titlefont;
  text-align: center;
  color: $titleColor;
  text-transform: uppercase;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 1px;
    transform: translateX(-50%);
    width: 90px;
    height: 2px;
    background-color: $titleColor;
  }
  &_black {
    color:$SctnTxtColor;
    line-height: 1;
    width: 30vw;
    text-align: center;
    margin: 0 auto 1.5rem;
    &:after{
      background-color: $SctnTxtColor;
    }
  }
}
.page__subtitle {
  text-align: center;
  font: 1rem/4rem $basefont;
  color:  $titleColor;
}
.section__title {
  font: 2.187rem/3rem  $titlefont;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  color: $SctnTxtColor;
  max-width: 270px;
  line-height: 1;
  margin: 0 auto 1rem;

  &::after {
    content: "";
    position: relative;
    left: 50%;
    display: block;
    overflow: hidden;
    width: 5.625rem;
    height: .187rem;
    margin: 0.5rem auto 0 (-5.625rem)/2;
    background-color: CurrentColor;
  }
  &_subtitle {
    @extend .section__title;
    color: white;
    margin-bottom: 0;
    &:after {
      content: none;
    }
  }
}
@import "blocks/header";
@import 'blocks/mainbox';
@import "blocks/auth";
@import "blocks/blog";
@import "common/footer";
@import "common/preloader";
@import "blocks/about";
@import "pages/works";