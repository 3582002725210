//remove after

.Background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), transparent 50%, rgba(0, 0, 0, 0.5));
}

// bugfix.. remove after
body {
  background: linear-gradient(to right,
          rgba($leftbgColor,1) 0%,
          rgba($leftbgColor,1) 50%,
          rgba($basefontColor,0) 50%,
          rgba($basefontColor,0) 100%);
}

//end remove

.about__me {

  &__section {
    max-width: 27.625rem;
    margin: 0 auto;
    position: relative;
  }
  &__title_bgr {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -9rem;
    margin-top: -3rem;
    fill: rgba(28, 62, 29, 0.12);
  }
  &__pic {
    background: $bgcolor url('../img/george-melidis.jpg') center no-repeat;
    background-size: cover;
    margin-bottom: 1rem;
    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    & img {
      display: none;
    }

  }
  &__canihelp {
    padding: 0 1.4rem;
  }
}

.about__contacts {
  position: relative;
  @include flexbox(row,nowrap,center,center);
}
.contact__box {
  position: absolute;
  padding: 1rem;
  left: 50%;
  top: 50vh;
  z-index: 10;
  background: rgba(white,0.8);
  border-radius: 0.25rem;
  margin-left: -180px;
  margin-top: -110px;
}
.contacts__link {
  font: 1rem/2rem $secondfont;
  text-decoration: none;
  color: $SctnTxtColor;
}
.contacts__icon {
  color: $firstColor;
  margin-right: 0.45rem;
}
#gmap {
  height: 100vh;
  width: 100%;
}

//-pie charts
.skill {
  font: 1.37rem/1.5rem $secondfont;
  color: $SctnTxtColor;
  margin: 2rem 0;
}

.skill__chart {

  &_item{
    display: inline-block;
    list-style: none;
    position: relative;
    width: 7rem;
    height: 7rem;
    margin-right: 0.8rem;
    text-align: center;
    font-size: 16px;
    &::after {
      content: attr(data-name);
      position: absolute;
      top: 50%;
      left: 17%;
      right: 17%;
      display: block;
      transform: translateY(-50%);
      text-align: center;
      font-size: 0.9rem;
      color: $firstColor;
    }
  }
  &_pie{
    border-radius: 50%;
    transform: rotate(-90deg);
  }
  &__cicle_bg, &__cicle_stroke{
    fill: transparent;
    stroke: #dfdcd5;
    stroke-width: 9;
  }
  &__cicle_bg {
    stroke-dasharray: none;
  }
  &__cicle_stroke {
    stroke: $firstColor;
  }


}

