/* font-family: "RobotoRegular"; */
@font-face {
  font-family: "Roboto Regular";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "Roboto Light"; */
@font-face {
  font-family: "Roboto Light";
  src: url("/assets/fonts/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "Roboto Medium"; */
@font-face {
  font-family: "Roboto Medium";
  src: url("/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "Roboto Bold"; */
@font-face {
  font-family: "Roboto Bold";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* font-family: "Sansus Webissimo Regular"; */
@font-face {
  font-family: "Sansus Webissimo Regular";
  src: url("/assets/fonts/Sansus-Webissimo-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

*, *::after, *::before {
  box-sizing: border-box; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

html, body {
  height: 100%;
  font-size: 16px; }

body {
  margin: 0;
  font-family: "Roboto Regular", sans-serif;
  font-size: 16px;
  color: #455a64; }
  body.homepage {
    background: url(../img/water-bgr.png) no-repeat center;
    background-size: cover;
    overflow: hidden; }

h1, h2, h3, h4, h5 {
  margin: 0; }

ol, ul, blockquote {
  margin: 0;
  padding: 0;
  list-style: none; }

a {
  font-family: "Roboto Medium", sans-serif;
  color: #6da3bd;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

p {
  line-height: 1.3rem; }

.btn {
  background: #6c9c5a; }
  .btn:hover {
    background-color: #4e8839; }

.btn__icon.fa.fa-link {
  margin-right: 0.4rem; }

.flx {
  display: flex; }

.fl-right {
  float: right; }

.fl-left {
  float: left; }

.tx-right {
  text-align: right; }

.icon {
  width: 35px;
  height: 25px;
  fill: rgba(255, 255, 255, 0.96);
  fill-opacity: .5;
  transition: fill-opacity .3s; }

a .icon:hover {
  fill: #4e8839; }

.container {
  position: relative; }

.fullscreen {
  width: 100%;
  height: 100%;
  margin: 0 auto; }

.main__page {
  margin: 0 auto;
  background: #fbf8f1; }

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between; }

.main_fullscreen {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.span3 {
  -sgs-span-settings: ("span": 3, "location": 1, "grid": 12, "gutter": 0.25, "style": "split", "start row": true, "end row": false, "fixed gutter": false, "split gutter": 1, "gutter property": "margin", "options": (null: null));
  width: 23.33333%;
  float: left;
  margin-right: -100%;
  margin-left: 0.83333%;
  clear: none; }

.span8 {
  -sgs-span-settings: ("span": 8, "location": 5, "grid": 12, "gutter": 0.25, "style": "split", "start row": false, "end row": true, "fixed gutter": false, "split gutter": 1, "gutter property": "margin", "options": (null: null));
  width: 65%;
  float: right;
  margin-left: 0;
  margin-right: 0.83333%;
  clear: none; }

.span6 {
  -sgs-span-settings: ("span": 6, "location": 1, "grid": 12, "gutter": 0.25, "style": "split", "start row": true, "end row": false, "fixed gutter": false, "split gutter": 1, "gutter property": "margin", "options": (null: null));
  width: 48.33333%;
  float: left;
  margin-right: -100%;
  margin-left: 0.83333%;
  clear: none; }

.span6_7 {
  -sgs-span-settings: ("span": 6, "location": 7, "grid": 12, "gutter": 0.25, "style": "split", "start row": false, "end row": true, "fixed gutter": false, "split gutter": 1, "gutter property": "margin", "options": (null: null));
  width: 48.33333%;
  float: right;
  margin-left: 0;
  margin-right: 0.83333%;
  clear: none; }

.span4 {
  -sgs-span-settings: ("span": 4, "location": 1, "grid": 12, "gutter": 0, "style": "split", "start row": true, "end row": false, "fixed gutter": false, "split gutter": 1, "gutter property": "margin", "options": (null: null));
  width: 33.33333%;
  float: left;
  margin-right: -100%;
  margin-left: 0%;
  clear: none; }

.span4_5 {
  -sgs-span-settings: ("span": 4, "location": 5, "grid": 12, "gutter": 0, "style": "split", "start row": false, "end row": false, "fixed gutter": false, "split gutter": 1, "gutter property": "margin", "options": (null: null));
  width: 33.33333%;
  float: left;
  margin-right: -100%;
  margin-left: 33.33333%;
  clear: none; }

.span4_8 {
  -sgs-span-settings: ("span": 4, "location": 9, "grid": 12, "gutter": 0, "style": "split", "start row": false, "end row": true, "fixed gutter": false, "split gutter": 1, "gutter property": "margin", "options": (null: null));
  width: 33.33333%;
  float: right;
  margin-left: 0;
  margin-right: 0%;
  clear: none; }

.page__title, .header__menu-nav__link {
  position: relative;
  font: 3.93rem/5rem "Sansus Webissimo Regular", sans-serif;
  text-align: center;
  color: #fff;
  text-transform: uppercase; }
  .page__title:after, .header__menu-nav__link:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 1px;
    transform: translateX(-50%);
    width: 90px;
    height: 2px;
    background-color: #fff; }
  .page__title_black {
    color: #566358;
    line-height: 1;
    width: 30vw;
    text-align: center;
    margin: 0 auto 1.5rem; }
    .page__title_black:after {
      background-color: #566358; }

.page__subtitle {
  text-align: center;
  font: 1rem/4rem "Roboto Regular", sans-serif;
  color: #fff; }

.section__title, .section__title_subtitle, .success {
  font: 2.187rem/3rem "Sansus Webissimo Regular", sans-serif;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  color: #566358;
  max-width: 270px;
  line-height: 1;
  margin: 0 auto 1rem; }
  .section__title::after, .section__title_subtitle::after, .success::after {
    content: "";
    position: relative;
    left: 50%;
    display: block;
    overflow: hidden;
    width: 5.625rem;
    height: .187rem;
    margin: 0.5rem auto 0 -2.8125rem;
    background-color: CurrentColor; }
  .section__title_subtitle {
    color: white;
    margin-bottom: 0; }
    .section__title_subtitle:after {
      content: none; }

.header {
  z-index: 1; }
  .header__page {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.36)), url(../img/water-bgr.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; }
  .header__top {
    margin: 1.3rem 1rem; }
  .header__menu_top {
    display: block;
    width: 2.5rem;
    height: 2.1875rem;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    z-index: 110;
    cursor: pointer; }
    .header__menu_top span {
      display: block;
      position: absolute;
      height: 0.312rem;
      width: 100%;
      background: #fff;
      border-radius: 0.312rem;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out; }
      .header__menu_top span:nth-child(1) {
        top: 0; }
      .header__menu_top span:nth-child(2) {
        top: 18px; }
      .header__menu_top span:nth-child(3) {
        top: 36px; }
    .header__menu_top.open span:nth-child(1) {
      top: 18px;
      transform: rotate(135deg); }
    .header__menu_top.open span:nth-child(2) {
      opacity: 0;
      left: -60px; }
    .header__menu_top.open span:nth-child(3) {
      top: 18px;
      transform: rotate(-135deg); }
  .header__menu-nav {
    display: none;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: foreground 1s forwards; }
    .header__menu-nav__item {
      margin-bottom: 2rem;
      text-align: center;
      opacity: 0; }
    .nav--open .header__menu-nav__item {
      opacity: 1;
      transition-delay: 1.4s;
      animation: showLinks .25s forwards;
      animation-delay: 1.4s; }
    .header__menu-nav__link {
      text-decoration: none;
      margin-bottom: 2rem;
      transition: opacity .5s; }
      .header__menu-nav__link:after {
        width: 0rem; }
      .header__menu-nav__link:hover:after {
        transition: width .2s;
        width: 5.625rem; }
    .header__menu-nav_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 100vh; }
  .header__main:before {
    content: "";
    position: absolute;
    background: url(../img/blog_header.svg) no-repeat;
    top: 14rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.10;
    width: 300px;
    height: 210px; }
  .header__footer {
    background: url("../img/bg.svg") top no-repeat;
    background-size: cover;
    height: 28vh;
    position: relative; }
    .header__footer_btn-down {
      position: absolute;
      left: 50%;
      top: 5rem;
      transform: translateX(-50%); }

.header__auth__btn {
  color: white;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 20px;
  box-shadow: none;
  padding: 10px 25px;
  margin-top: 30px;
  transition: color .3s, background-color .3s;
  cursor: pointer; }
  .header__auth__btn:hover {
    border-color: #4e8839;
    background-color: #4e8839; }

@keyframes foreground {
  0% {
    background: linear-gradient(rgba(108, 156, 90, 0.8), rgba(78, 136, 57, 0.8)) left, linear-gradient(rgba(108, 156, 90, 0.8), rgba(78, 136, 57, 0.8)) right;
    background-size: 0 50%;
    background-repeat: no-repeat; }
  90% {
    background: linear-gradient(rgba(108, 156, 90, 0.8), rgba(78, 136, 57, 0.8)) left, linear-gradient(rgba(108, 156, 90, 0.8), rgba(78, 136, 57, 0.8)) right;
    background-size: 50% 100%;
    background-repeat: no-repeat; }
  100% {
    background: rgba(108, 156, 90, 0.8);
    background-size: 50% 100%;
    background-repeat: no-repeat; } }

@keyframes showLinks {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.mainbox {
  width: 380px;
  background: rgba(18, 38, 32, 0.6);
  box-sizing: border-box;
  padding: 20px 15px; }

.profile, .contact__form {
  color: #fff;
  border-radius: 5px;
  width: 380px;
  min-height: 380px;
  text-align: center;
  position: relative;
  transform-style: preserve-3d;
  transition: transform .6s; }
  .profile--flip {
    transform: rotateY(180deg); }
  .profile__home {
    transform: rotateY(0deg); }
  .profile__login {
    transform: rotateY(180deg); }
  .profile__home, .profile__login {
    display: block;
    position: absolute;
    transition: opacity .5s;
    backface-visibility: hidden; }
  .profile__avatar_round {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 15px; }
  .profile__name {
    font-family: "Roboto Medium", sans-serif;
    font-size: 1.75rem;
    font-weight: normal;
    margin-bottom: 0.66rem; }
  .profile__title {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 2rem; }
  .profile__socials__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.6rem; }
  .profile__socials__icon {
    margin: 0 0.375rem; }
  .profile__menu__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center; }
  .profile__menu__item {
    flex-grow: 1;
    border-right: 2px solid rgba(255, 255, 255, 0.7); }
    .profile__menu__item:last-child {
      border-right: 0; }
  .profile__menu__link, .form-button {
    font-family: "Roboto Medium", sans-serif;
    line-height: 45px;
    color: #fff;
    width: 100%;
    display: inline-block;
    background: rgba(108, 156, 90, 0.85);
    transition: background-color 0.3s; }
    .profile__menu__link:hover, .form-button:hover {
      background-color: rgba(78, 136, 57, 0.85); }

.login__title {
  font: 2.93rem/4rem "Sansus Webissimo Regular", sans-serif; }

.login__form__input__group, .form-input, .form-textarea {
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: hidden; }
  .login__form__input__group_icon {
    height: 45px;
    background-color: rgba(169, 169, 169, 0.5);
    display: flex;
    flex-direction: inherit;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex: 1 14%; }
    .login__form__input__group_icon svg {
      width: 19px;
      height: 19px; }
  .login__form__input__group_input {
    padding-left: 10px;
    flex: 1 86%; }

.login__form__checkbox__group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: inherit;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center; }
  .login__form__checkbox__group_checkbox {
    display: none; }
    .login__form__checkbox__group_checkbox:checked + .login__form__checkbox__group_label:before {
      background-position: -22px 0px; }
  .login__form__checkbox__group_label {
    cursor: pointer; }
    .login__form__checkbox__group_label:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      vertical-align: middle;
      margin-right: 10px;
      background-image: url(../img/sprite.png);
      background-position: 0 0; }

.login__form__question {
  font-weight: bold;
  margin-bottom: 10px; }

.login__form__radio_list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center; }
  .login__form__radio_list_item:first-child {
    margin-right: 20px; }
  .login__form__radio_list_input {
    display: none; }
    .login__form__radio_list_input:checked + .login__form__radio_list_label:before {
      background-position: -22px -22px; }
  .login__form__radio_list_label {
    cursor: pointer; }
    .login__form__radio_list_label:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      vertical-align: middle;
      margin-right: 10px;
      background-image: url(../img/sprite.png);
      background-position: 0 -22px; }

.blog {
  position: relative; }
  .blog--content {
    box-sizing: border-box; }

.blog__menu {
  position: relative;
  text-align: right; }
  .blog__menu--sticky {
    position: fixed;
    top: 10px; }
  .blog__menu--fix-bottom {
    position: absolute;
    bottom: 0;
    padding-bottom: 1rem; }

.blog__menu__item {
  font-weight: bold;
  color: #455a64;
  margin-bottom: 1rem;
  padding: 1rem;
  border-right: 2px solid transparent; }
  .blog__menu__item--active {
    border-right: 2px solid #4e8839; }

.blog__menu__link {
  color: inherit; }
  .blog__menu__link:hover {
    color: #4e8839; }

footer {
  width: 100%; }
  footer .footer {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center; }
  footer .footer_copyright {
    color: #fff;
    text-align: center;
    line-height: 2.375rem; }

.main__page__footer {
  color: #fff;
  padding: 2rem 0;
  background-color: rgba(55, 62, 66, 0.85); }
  .main__page__footer_col {
    padding: 0 2rem; }
  .main__page__footer .profile__socials__list {
    justify-content: flex-start; }

.span4_5 .main__page__footer_col {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff; }

.footermenu {
  font-family: "Roboto Medium", sans-serif; }
  .footermenu__list {
    margin-bottom: 1rem;
    margin-left: 0.375rem; }
  .footermenu__item {
    display: inline-block;
    margin-right: 1rem; }
  .footermenu__link {
    color: #fff;
    text-decoration: none; }

.footer__siteabout {
  font: 1rem/1.55rem "Roboto Light", sans-serif;
  padding: 0 1rem; }

.footer__quote {
  font-family: "Roboto Light", sans-serif;
  font-style: italic;
  line-height: 1.5rem; }
  .footer__quote_author {
    font-weight: bold; }

.page .footer {
  height: inherit;
  background-color: rgba(78, 136, 57, 0.85); }

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: green; }
  .preloader__spinner {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .preloader__animation {
    width: 150px;
    height: 150px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: #fff;
    position: relative;
    animation: spin 2s linear infinite; }
    .preloader__animation:before, .preloader__animation:after {
      content: '';
      position: absolute;
      border: 3px solid transparent;
      border-radius: 50%;
      border-top-color: #fff; }
    .preloader__animation:before {
      top: 5px;
      right: 5px;
      bottom: 5px;
      left: 5px;
      animation: spin 3s linear infinite; }
    .preloader__animation:after {
      top: 15px;
      right: 15px;
      bottom: 15px;
      left: 15px;
      animation: spin 1.5s linear infinite; }
  .preloader__percents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.Background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.Background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), transparent 50%, rgba(0, 0, 0, 0.5)); }

body {
  background: linear-gradient(to right, #ebefe2 0%, #ebefe2 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%); }

.about__me__section {
  max-width: 27.625rem;
  margin: 0 auto;
  position: relative; }

.about__me__title_bgr {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -9rem;
  margin-top: -3rem;
  fill: rgba(28, 62, 29, 0.12); }

.about__me__pic {
  background: #fbf8f1 url("../img/george-melidis.jpg") center no-repeat;
  background-size: cover;
  margin-bottom: 1rem; }
  .about__me__pic:before {
    content: '';
    display: block;
    padding-bottom: 100%; }
  .about__me__pic img {
    display: none; }

.about__me__canihelp {
  padding: 0 1.4rem; }

.about__contacts {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center; }

.contact__box {
  position: absolute;
  padding: 1rem;
  left: 50%;
  top: 50vh;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.25rem;
  margin-left: -180px;
  margin-top: -110px; }

.contacts__link {
  font: 1rem/2rem "Roboto Medium", sans-serif;
  text-decoration: none;
  color: #566358; }

.contacts__icon {
  color: #6c9c5a;
  margin-right: 0.45rem; }

#gmap {
  height: 100vh;
  width: 100%; }

.skill {
  font: 1.37rem/1.5rem "Roboto Medium", sans-serif;
  color: #566358;
  margin: 2rem 0; }

.skill__chart_item {
  display: inline-block;
  list-style: none;
  position: relative;
  width: 7rem;
  height: 7rem;
  margin-right: 0.8rem;
  text-align: center;
  font-size: 16px; }
  .skill__chart_item::after {
    content: attr(data-name);
    position: absolute;
    top: 50%;
    left: 17%;
    right: 17%;
    display: block;
    transform: translateY(-50%);
    text-align: center;
    font-size: 0.9rem;
    color: #6c9c5a; }

.skill__chart_pie {
  border-radius: 50%;
  transform: rotate(-90deg); }

.skill__chart__cicle_bg, .skill__chart__cicle_stroke {
  fill: transparent;
  stroke: #dfdcd5;
  stroke-width: 9; }

.skill__chart__cicle_bg {
  stroke-dasharray: none; }

.skill__chart__cicle_stroke {
  stroke: #6c9c5a; }

.works__section {
  position: relative;
  margin-bottom: 5rem; }

.works__title_bgr {
  transform: translate(-50%, -50%) scale(1.68);
  position: absolute;
  top: 0;
  left: 50%;
  width: inherit;
  height: inherit;
  margin-top: 1rem;
  fill: rgba(28, 62, 29, 0.12); }

.slider {
  height: 100vh;
  min-height: 600px; }
  .slider__desc {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    float: left;
    width: 50%;
    height: 100%;
    padding: 1.25rem;
    background: #EFEEE9;
    text-align: center; }
    .slider__desc:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%; }
  .slider__desc-in {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 26.25rem;
    margin: auto; }
  .slider__title {
    margin-bottom: .9em; }
  .slider__info {
    padding: 0.8rem 0.625rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(78, 136, 57, 0.3);
    border-left: none;
    border-right: none;
    color: #4e8839;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase; }
  .slider__link {
    color: white;
    padding: 1rem;
    border-radius: 1.25rem; }
  .slider__view {
    float: right;
    width: 50%;
    height: calc(100% - 14.06rem);
    background: #EFEBE0;
    text-align: center; }
  .slider__controls {
    float: right;
    width: 50%;
    font-size: 0; }
  .slider__control {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 14.06rem;
    color: white;
    text-align: center;
    background: radial-gradient(at top, #fff, transparent 65%), linear-gradient(transparent, rgba(0, 0, 0, 0.15) 75%, transparent 85%);
    background-repeat: no-repeat;
    overflow: hidden; }
    .slider__control:before {
      content: '';
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(136, 131, 114, 0.5); }
    .slider__control:after {
      content: '';
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 4.6rem;
      height: 4.6rem;
      margin: auto;
      font: 4.6rem/1 Fontawesome;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
      transition: opacity .5s, transform .5s; }
    .slider__control:hover:after {
      opacity: .25;
      transform: scale(3); }
  .slider__control--prev:before {
    background: rgba(182, 180, 174, 0.7); }
  .slider__control--prev:after {
    content: '\f078'; }
  .slider__control--next:after {
    content: '\f077'; }
  .slider-data {
    display: none; }

.slides {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden; }

.slides__item {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0; }
  .slides__item--active {
    opacity: 1; }
  .slides__item img {
    display: block;
    max-width: 90%;
    max-height: 90%;
    margin: auto; }

[class*='slides__item--animate'] {
  opacity: 1;
  transition: top 1s; }

.slides__item--animate-down.slides__item--active {
  top: 100%; }

.slides__item--animate-down:not(.slides__item--active) {
  transform: translateY(-100%);
  top: 100%; }

.slides__item--animate-up.slides__item--active {
  top: -100%; }

.slides__item--animate-up:not(.slides__item--active) {
  transform: translateY(100%);
  top: -100%; }

.slides__item--animate-fade {
  transition: opacity 1s; }

.slides__item--animate-fade.slides__item--active {
  opacity: 0; }

.slides__item--animate-fade:not(.slides__item--active) {
  opacity: 1; }

@media (max-width: 768px) {
  .slider {
    display: flex;
    flex-flow: row wrap;
    height: auto;
    min-height: 0; }
  .slider__desc,
  .slider__view,
  .slider__controls {
    width: 100%; }
  .slider__view {
    order: -1;
    height: 68vw; }
  .slider__desc {
    padding: 5.75% 1.25rem 8%; }
  .slider__desc-in {
    display: block;
    max-width: none; }
    .slider__desc-in:after {
      display: none; }
  .slider__title {
    margin-bottom: 1.3rem; }
  .slider__info {
    max-width: 35rem;
    margin: 0 auto 1.25rem; }
  .slider__control {
    height: 28vw; } }

@media (max-width: 480px) {
  .slider__desc {
    padding-left: 1rem;
    padding-right: 1rem; }
  .slider__info {
    max-width: none;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: inherit; } }

@media screen and (min-width: 769px) and (min-height: 600px) and (orientation: portrait) {
  .slider {
    height: 600px; } }

.testimonials {
  position: relative;
  padding: 5rem 0;
  background: white url(../img/form-bgr.png) center bottom;
  background-size: cover; }
  .testimonials__body {
    max-width: 50rem;
    margin: 0 auto;
    padding: 0 1.25rem; }
  .testimonials_title {
    position: relative; }
  .testimonials__wrap {
    font-size: 0;
    margin-bottom: 3rem; }
  .testimonials__block {
    display: inline-block;
    width: 50%;
    padding: 0 0.8rem 2rem;
    vertical-align: top;
    font-size: 1rem; }
  .testimonials__avatar {
    width: 4.875rem;
    height: 4.875rem;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden; }
  .testimonials__content {
    font-style: italic; }
  .testimonials__person {
    text-align: center; }
  .testimonials__name {
    font-weight: bold;
    color: #4e8839; }

.contact__form {
  background: rgba(235, 239, 226, 0.8);
  margin: auto;
  padding: 1rem 0 0; }
  .contact__form_body {
    padding: 0 1rem; }
  .contact__form_title {
    padding: 1.25rem 0;
    max-width: 19rem; }
  .contact__form_footer {
    font-size: 0; }

.form-item {
  display: inline-block;
  width: 50%;
  border-right: 1px solid white; }
  .form-item:last-child {
    border-right: 0; }

.form-input, .form-textarea {
  outline: none;
  width: 100%;
  padding-left: 1.5rem;
  height: 2.81rem;
  transition: background-color .2s;
  background-color: rgba(255, 255, 255, 0.85);
  border: 0; }
  .form-input:focus, .form-textarea:focus {
    background-color: white; }

.form-textarea {
  padding-top: 1rem;
  height: 8.75rem; }

.form-button {
  cursor: pointer;
  border: 0;
  font-size: 1rem; }

.success {
  color: #6c9c5a; }
