/* font-family: "RobotoRegular"; */
@font-face {
  font-family: "Roboto Regular";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "Roboto Light"; */
@font-face {
  font-family: "Roboto Light";
  src: url("/assets/fonts/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "Roboto Medium"; */
@font-face {
  font-family: "Roboto Medium";
  src: url("/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "Roboto Bold"; */
@font-face {
  font-family: "Roboto Bold";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "Sansus Webissimo Regular"; */
@font-face {
  font-family: "Sansus Webissimo Regular";
  src: url("/assets/fonts/Sansus-Webissimo-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}