.mainbox {
  width: 380px;
  background: rgba(#122620, 0.6);
  box-sizing: border-box;
  padding: 20px 15px;
}
.profile {
  color: #fff;
  border-radius: 5px;
  width: 380px;
  min-height: 380px;
  //overflow: hidden;
  text-align: center;
  position: relative;
  transform-style: preserve-3d;
  transition: transform .6s;

  &--flip {
    transform: rotateY(180deg);
  }


  &__home {transform: rotateY(0deg);}
  &__login {transform: rotateY(180deg);}
  &__home, &__login {
    display: block;
    position: absolute;
    transition: opacity .5s;
    //overflow: hidden;
    backface-visibility: hidden;
  }





  &__avatar_round {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 15px;
  }
  &__name {
    font-family: $secondfont;
    font-size: 1.75rem;
    font-weight: normal;
    margin-bottom: 0.66rem;
  }
  &__title{
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 2rem;
  }
  &__socials__list{
    @include flexbox(row,nowrap,center,center);
    margin-bottom: 0.6rem;
  }
  &__socials__icon{
    margin: 0 0.375rem;
  }
  &__menu__list {
    @include flexbox(row,nowrap, space-around, center );
  }
  &__menu__item {
    flex-grow: 1;
    border-right: 2px solid rgba($basefontColor, 0.7);
    &:last-child {
      border-right: 0;
    }
  }
  &__menu__link {
    font-family: $secondfont;
    line-height: 45px;
    color: $basefontColor;
    width: 100%;
    display: inline-block;
    background: rgba($firstColor,0.85);
    transition: background-color 0.3s;
    &:hover {
      background-color: rgba($secondColor, 0.85);
    }
  }


}
