
@mixin flexbox($direction:null, $wrap: null, $jcontent: null, $align: null){
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $jcontent;
  align-items: $align;
}

@mixin formSelectors($x, $y) {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 10px;
  background-image: url(../img/sprite.png);
  background-position: $x $y;
}

@mixin svgBgr($name) {
  background-image: url(../img/sprite.svg##{$name});
}
