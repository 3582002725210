.blog{
  position: relative;

  &--content {
    box-sizing: border-box;
  }
}
.blog__menu {
  position: relative;
  text-align: right;

  &--sticky{
    position: fixed;
    top: 10px;
  }
  &--fix-bottom {
    position: absolute;
    bottom: 0;
    padding-bottom: 1rem;
  }
}
.blog__menu__item{
  font-weight: bold;
  color: $textColor;
  margin-bottom: 1rem;
  padding: 1rem;
  border-right: 2px solid transparent;

  &--active {
    border-right: 2px solid $colorActive;
  }
}

.blog__menu__link {
  color: inherit;

  &:hover {
    color: $colorActive;
  }
}