
.testimonials {
  position: relative;
  padding: 5rem 0;
  background: white url(../img/form-bgr.png) center bottom;
  background-size: cover;

  &__body {
    max-width: 50rem;
    margin: 0 auto;
    padding: 0 1.25rem;

  }
  &_title {
    position: relative;
  }
  &__wrap {
    font-size:0;
    margin-bottom: 3rem;
  }
  &__block{
    display: inline-block;
    width: 50%;
    padding: 0 0.8rem 2rem;
    vertical-align: top;
    font-size: 1rem;
  }
  &__content {

  }
  &__avatar {
    width: 4.875rem;
    height: 4.875rem;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
  }
  &__content {
    font-style: italic;
  }
  &__person {
    text-align: center;
  }
  &__name{
    font-weight:bold;
    color: $colorActive;
  }
  &__desc {

  }

}