// fonts
$basefont: "Roboto Regular", sans-serif;
$secondfont: "Roboto Medium", sans-serif;
$basefontBold: "Roboto Bold", sans-serif;
$basefontLight: "Roboto Light", sans-serif;
$titlefont: "Sansus Webissimo Regular", sans-serif;

//colors
$firstColor:    #6c9c5a;  //Base color (Green)
$colorActive:   #4e8839;  //Second color for active elements
$SctnTxtColor:  #566358;  //Section title Color and for footer
$footerbgColor: #373e42;  //Footer background and text color
$bgcolor:       #fbf8f1;
$baselink:      #6da3bd;  //Base Link color
$basefontColor: #fff;
$textColor:     #455a64;
$secondColor:   #4e8839;
$titleColor:    #fff;
$leftbgColor:   #ebefe2;  //background color for slider and other sections



//mediaqueries
$desktop: 1200px;
$laptop:  992px;
$tablet: 768px;
$mobile: 480px;

//set to desktop first!
@include breakpoint-set('default feature', max-width);