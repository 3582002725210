.login__title {
  font: 2.93rem/4rem $titlefont;
}
.login__form{

  // Login and passwords
  &__input__group {
    margin-bottom: 20px;
    border-radius: 20px;
    overflow: hidden;

    &_icon {
      height: 45px;
      background-color: rgba(darkgray, 0.5);
      @include flexbox(inherit, nowrap, center, center);
      flex: 1 14%;

      svg {
        width: 19px;
        height: 19px;
      }
    }
    &_input {
      padding-left: 10px;
      flex: 1 86%;
    }
  }

  //checkboxex Я человек
  &__checkbox__group {
    margin-bottom: 20px;
    @include flexbox(inherit,nowrap,center, center);

    &_checkbox {
      display: none;
      &:checked + .login__form__checkbox__group_label {
        &:before {
          background-position: -22px 0px;
        }
      }
    }
    &_label {
      cursor: pointer;
      &:before {
      @include formSelectors(0,0);

      }
    }
  }
  // Вы точно не робот?
  &__question {
    font-weight: bold;
    margin-bottom: 10px;
  }

  // Да */ Нет radio buttons
  &__radio_list {
    @include flexbox(row,nowrap, center, center );

    &_item:first-child {
      margin-right: 20px;
    }
    &_input {
      display: none;
      &:checked + .login__form__radio_list_label{
        &:before {
          background-position: -22px -22px;
        }
      }
    }
    &_label {
      cursor: pointer;
      &:before {
        @include formSelectors(0, -22px);
      }
    }

  }
}