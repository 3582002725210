.header {
  z-index: 1;
  &__page {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.36)), url(../img/water-bgr.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //height: 32vh; enable after
    overflow: hidden;
  }
  &__top {
    margin: 1.3rem 1rem;
  }
  &__menu_top {
    display: block;
    width: 2.5rem;
    height: 2.1875rem;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    z-index: 110;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 0.312rem;
      width: 100%;
      background: $titleColor;
      border-radius: 0.312rem;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 18px;
      }
      &:nth-child(3) {
        top: 36px;
      }
    }
    &.open span:nth-child(1) {
      top:18px;
      transform: rotate(135deg);
    }
    &.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }
    &.open span:nth-child(3) {
      top:18px;
      transform: rotate(-135deg);
    }



  }
  &__menu-nav {
    display: none;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: foreground 1s forwards;

    &__item {
      margin-bottom: 2rem;
      text-align: center;
      opacity: 0;
    }
    .nav--open &__item {
      opacity: 1;
      transition-delay: 1.4s;
      animation: showLinks .25s forwards;
      animation-delay: 1.4s;
    }
    &__link {
      @extend .page__title;
      text-decoration: none;
      margin-bottom: 2rem;
      transition: opacity .5s;

      &:after {
        width: 0rem;
      }

      &:hover {
        &:after {
          transition: width .2s;
          width: 5.625rem;
        }
      }
    }

    &_wrapper {
      @include flexbox(row, wrap, center, center);
      height:100vh;
    }
  }
  &__main {
    &:before {
      content: "";
      position: absolute;
      background: url(../img/blog_header.svg) no-repeat;
      top: 14rem;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.10;
      width: 300px;
      height: 210px;
    }
  }
  &__footer {
    background: url('../img/bg.svg') top no-repeat;
    background-size: cover;
    height: 28vh;
    position: relative;

    &_btn-down {
      position: absolute;
      left: 50%;
      top: 5rem;
      transform: translateX(-50%);
    }
  }
}
.header__auth__btn {
  color: white;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 20px;
  box-shadow: none;
  padding: 10px 25px;
  margin-top: 30px;
  transition: color .3s, background-color .3s;
  cursor: pointer;
  &:hover {
    border-color: $colorActive;
    background-color: $colorActive;
  }
}

@keyframes foreground {
  0% {
    background: linear-gradient(rgba($firstColor, 0.8), rgba($colorActive, 0.8)) left,
                linear-gradient(rgba($firstColor, 0.8), rgba($colorActive, 0.8)) right;
    background-size: 0 50%;
    background-repeat: no-repeat; }
  90% {
    background: linear-gradient(rgba($firstColor, 0.8), rgba($colorActive, 0.8)) left,
                linear-gradient(rgba($firstColor, 0.8), rgba($colorActive, 0.8)) right;
    background-size: 50% 100%;
    background-repeat: no-repeat; }

  100%{
    background: rgba($firstColor, 0.8);
    background-size: 50% 100%;
    background-repeat: no-repeat;
  }
}
@keyframes showLinks {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}